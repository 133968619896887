<template>
  <BaseCard
    ref="base-card"
    :loading="loading"
    :title-visible="false"
    :footer-visible="false"
  >
    <template #title-free>
      <b-row class="m-2" align-v="center">
        <b-col cols="auto">
          <h2 class="font-weight-bold text-capitalize text-dark mb-0">
            {{ mainCertification ? mainCertification.code : "" }}
            <StatusBadge
              v-if="status.name === 'Facturada'"
              class="font-small-3 ml-1"
              :status="status.name"
              :text="status.name"
            />
            <StatusSelect
              v-else
              v-model="status"
              class="font-small-3 d-inline-block status-select"
              :type="statusType"
              :clearable="false"
              @selection-change="handleUpdateMaintenance('status', $event)"
            />
          </h2>
          <div class="d-flex align-items-center">
            <template v-if="!editDate">
              <small class="font-weight-bold">{{
                mainCertificationDateText
              }}</small>
              <b-link>
                <span class="pl-1" @click="editDate = true">
                  <feather-icon
                    v-b-tooltip.hover
                    title="Editar"
                    icon="Edit2Icon"
                    size="15"
                  />
                </span>
              </b-link>
            </template>
            <b-form-group
              v-if="editDate"
              label="Fecha"
              label-for="certification-date"
            >
              <b-input-group>
                <b-form-datepicker
                  id="certification-date"
                  :value="mainCertification.maintenance_certification_date"
                  :date-format-options="{
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric',
                  }"
                  size="sm"
                  :hide-header="true"
                  placeholder="Fecha"
                  @input="handlerUpdateDate($event)"
                  start-weekday="1"
                />
                <b-input-group-append>
                  <b-btn size="sm" variant="danger" @click="editDate = false">
                    Cancelar
                  </b-btn>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-col>
        <b-col cols="auto" class="d-flex align-items-center">
          <b-form-group
            v-if="isMaintenance"
            label="Contrato de mantenimiento"
            label-for="certification-client"
          >
            <BaseSelect
              label="name"
              :clearable="false"
              :placeholder="'Contrato de mantenimiento'"
              :value="mainCertification ? mainCertification.job_contract : {}"
              :http-body="{
                job_id: mainCertification ? mainCertification.job.id : {},
              }"
              :resource="$data.$constants.RESOURCES.RESOURCE_JOBS_CONTRACTS"
              @input="handleContractSelected($event)"
            />
          </b-form-group>
        </b-col>
        <b-col cols="auto">
          <b-form-group
            v-if="isMaintenance"
            label="Categoria de revisión"
            label-for="revision-type"
          >
            <BaseSelect
              ref="revision-type"
              :httpMethod="'get'"
              :reduce="(item) => item.id"
              :value="mainCertification ? mainCertification.revision_type : {}"
              :resource="'type-revision'"
              :clearable="false"
              @input="handleRevisionType($event)"
            />
          </b-form-group>
        </b-col>
        <b-col cols="auto" class="ml-auto">
          <div class="d-flex justify-content-end align-items-center">
            <b-button
              v-if="status.name === 'Pendiente de facturar'"
              variant="primary"
              class="rounded-sm px-2 my-1"
              @click="handleInvoiceButtonClick"
            >
              Facturar
            </b-button>
            <b-link>
              <span
                class="d-inline-block px-50 text-danger"
                @click="handleDeleteButtonClick()"
              >
                <feather-icon
                  v-b-tooltip.hover
                  title="Eliminar"
                  icon="TrashIcon"
                  size="18"
                />
              </span>
            </b-link>
          </div>
        </b-col>
        <b-col v-if="status.name === 'Facturada'" cols="12">
          <b-link
            size="sm"
            class="text-decoration-none text-indigo"
            :to="{
              name: 'viewSaleInvoice',
              params: { id: mainCertification.sale_invoice.id },
            }"
          >
            <u>
              Factura:
              {{ mainCertification ? mainCertification.sale_invoice.code : "" }}
            </u>
          </b-link>
        </b-col>
      </b-row>
    </template>
    <hr class="m-0" />
    <ProfilePanel :profile="mainCertificationProfile" :autoCols="true">
      <template #Observaciones>
        <b-form-group label="Observaciones" label-for="observations">
          <b-form-textarea
            id="observations"
            v-model="observationsHandler"
            @change="handleUpdateMaintenance('observations', observations)"
          />
        </b-form-group>
      </template>
      <template #documentation>
        <BaseFileInput :multiple="false" @change="handleFileInputChange">
          <b-button variant="primary" class="text-nowrap rounded-sm px-1">
            + Adjuntar archivo
          </b-button>
        </BaseFileInput>
      </template>
      <template #Documentacion>
        <div class="font-weight-bold">Documentacion:</div>
        <div
          v-if="
            mainCertification.documentation &&
            mainCertification.documentation.length > 0
          "
          v-for="document in mainCertification.documentation"
          class="d-flex justify-content-end align-items-center pt-50"
        >
          <b-link
            class="text-indigo pr-1"
            @click="handleDownloadDocument(document)"
          >
            {{ document.name }}
          </b-link>
          <b-link @click="handleDeleteDocument(document.id)">
            <span class="text-danger">
              <feather-icon
                v-b-tooltip.hover
                title="Eliminar"
                icon="TrashIcon"
                size="18"
              />
            </span>
          </b-link>
        </div>
      </template>
    </ProfilePanel>
  </BaseCard>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ApiRestService from "@/api/base-api";
import StatusSelect from "@/components/status/select/StatusSelect.vue";
import StatusBadge from "@/components/status/badge/StatusBadge.vue";
import BaseSelect from "@/components/ui/select/BaseSelect.vue";
import ProfilePanel from "@/components/ui/panel/ProfilePanel.vue";
import MaintenanceCertificationsApi from "@/api/maintenance-certifications";
import BaseCard from "@/components/ui/card/BaseCard.vue";
import { MAINTENANCE_CERTIFICATIONS_TYPE } from "@/api/status-api";
import BaseFileInput from "@/components/ui/file/BaseFileInput.vue";

export default {
  components: {
    BaseCard,
    StatusSelect,
    ProfilePanel,
    StatusBadge,
    BaseSelect,
    BaseFileInput,
  },
  data() {
    return {
      loading: false,
      statusType: MAINTENANCE_CERTIFICATIONS_TYPE,
      status: {},
      editDate: false,
      observations: "",
    };
  },
  computed: {
    ...mapGetters("maintenanceCertifications", {
      mainCertification: "getMaintenanceCertification",
    }),
    mainCertificationDateText() {
      if (
        !this.mainCertification ||
        !this.mainCertification.maintenance_certification_date
      ) {
        return "Sin fecha de oferta";
      }

      return new Date(
        this.mainCertification.maintenance_certification_date
      ).toLocaleDateString();
    },
    mainCertificationProfile() {
      const cols = [
        {
          label: "Cliente",
          value: this.mainCertification?.client?.name || "Sin cliente",
        },
        {
          label: "Job",
          value: this.mainCertification?.job?.name || "Sin job",
          link: !!this.mainCertification?.job,
          id: this.mainCertification?.job?.id,
          routeName: "viewJob",
        },
        {
          label: "Observaciones",
          value: this.mainCertification?.client?.name || "Sin cliente",
        },
        {
          label: "documentation",
          value: "",
        },
      ];

      return this.mainCertification?.documentation?.length > 0
        ? [
            ...cols,
            {
              label: "Documentacion",
              value: "",
            },
          ]
        : cols;
    },
    observationsHandler: {
      set(value) {
        this.observations = value;
      },
      get() {
        return this.mainCertification?.observations;
      },
    },
    isMaintenance() {
      return this.mainCertification?.division?.reference_code === "M";
    },
  },
  watch: {
    mainCertification: {
      handler() {
        this.status = this.mainCertification.status;
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("maintenanceCertifications", [
      "loadMaintenanceCertification",
    ]),
    loadListRevisionType() {
      this.$refs["revision-type"]?.loadData();
    },
    async handleDeleteButtonClick() {
      const response = await this.$modal.show({
        title: "¿Está seguro?",
        text: "Se borrará el registro.",
      });

      if (!response.isConfirmed) {
        return;
      }

      this.loading = true;
      try {
        await MaintenanceCertificationsApi.delete(this.mainCertification.id);
        await this.$router.push({ name: "maintenanceCertifications" });
      } finally {
        this.loading = false;
      }
    },
    async handleUpdateMaintenance(key, value) {
      this.loading = true;
      let params = {};
      params[key] = value;

      try {
        await MaintenanceCertificationsApi.edit(
          this.mainCertification.id,
          params
        );
        this.loadMaintenanceCertification(this.mainCertification.id);
      } catch (_) {
        this.loadMaintenanceCertification(this.mainCertification.id);
      } finally {
        this.loading = false;
      }
    },
    async handleInvoiceButtonClick() {
      this.loading = true;
      try {
        await MaintenanceCertificationsApi.createSaleInvoice(
          this.mainCertification.id
        );
        await this.loadMaintenanceCertification(this.mainCertification.id);
      } finally {
        this.loading = false;
      }
    },
    handlerUpdateDate(date) {
      this.handleUpdateMaintenance("maintenance_certification_date", date);
      this.editDate = false;
    },
    handleContractSelected(contract) {
      this.loading = true;

      this.handleUpdateMaintenance("job_contract_id", contract.id);
    },
    handleRevisionType(revision_type) {
      this.loading = true;

      this.handleUpdateMaintenance("revision_type", revision_type);
    },
    handleFileInputChange(document) {
      this.handleUpdateMaintenance("documentation", document);
    },
    async handleDownloadDocument(document) {
      if (!document || document.length === 0) {
        return;
      }

      this.loading = true;
      try {
        await ApiRestService.downloadArchive(document.id, document.name);
      } finally {
        this.loading = false;
      }
    },
    async handleDeleteDocument(fileId) {
      const response = await this.$modal.show({
        title: '¿Está seguro?',
        text: `El documento se borrará permanentemente`,
      })

      if (!response.isConfirmed) {
        return
      }

      this.loading = true;

      try {
        await MaintenanceCertificationsApi.deleteDocument(
          this.mainCertification.id,
          fileId
        );
        await this.loadMaintenanceCertification(this.mainCertification.id);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.loadListRevisionType();
  },
};
</script>

<style lang="scss" scoped>
.status-select {
  ::v-deep .vs__dropdown-toggle {
    border: none;
  }
}
</style>
