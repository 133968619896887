<template>
  <b-card no-body>
    <b-overlay
      :show="loading"
      variant="white"
      spinner-variant="primary"
      rounded="sm"
    >
      <b-row
        class="p-2"
        align-v="center"
        align-h="between"
      >
        <b-col cols="auto">
          <h3 class="text-uppercase font-weight-bold mb-0">
            conceptos e importes
          </h3>
        </b-col>
        <b-col cols="auto">
          <span
            class="text-indigo border-bottom border-indigo font-weight-bold mr-1 cursor-pointer font-small-4"
            @click="createEmptyLine"
          >
            + Nuevo concepto / importe
          </span>
        </b-col>
      </b-row>
      <b-table
        hover
        responsive
        :items="mainCertificationLines"
        :fields="columns"
        :no-local-sorting="true"
      >
        <template #cell(product)="data">
          <ProductSelect
            v-model="data.item.product"
            @option-selected="handleProductSelectChange($event, data.item)"
            @clear="handleProductSelectChange($event, data.item)"
          />
        </template>
        <template #cell(description)="data">
          <b-form-input
            v-model="data.item.description"
            placeholder="Descripción"
            @change="updateLine(data.item)"
          />
        </template>
        <template #cell(amount)="data">
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="data.item.amount"
              placeholder="importe"
              type="number"
              @change="updateLine(data.item)"
            />
            <b-input-group-append is-text>
              €
            </b-input-group-append>
          </b-input-group>
        </template>
        <template #cell(units)="data">
          <b-form-input
            v-model="data.item.units"
            placeholder="Unidades"
            type="number"
            @change="updateLine(data.item)"
          />
        </template>
        <template #cell(total)="data">
          {{ data.item.total | numberToLocalString }} €
        </template>
        <template #cell(actions)="data">
          <span
            class="d-inline-block px-50 text-danger"
            @click="handleDeleteIconClick(data.item.id)"
          >
            <feather-icon
              v-b-tooltip.hover 
              title="Eliminar"
              icon="TrashIcon"
              size="18"
            />
          </span>
        </template>
      </b-table>
      <b-row
        align-h="around"
        align-v="end"
        no-gutters
        class="p-1 bg-action-blue flex-column"
      >
        <b-col
          cols="auto"
          class="text-right"
        >
          <span class="text-dark-gray font-very-small font-weight-bold">TOTAL BRUTO: </span>
          <span class="text-gray-50 justify-content-end d-inline-flex total-value-label">
            {{ (mainCertification ? mainCertification.total_gross : 0) | numberToLocalString }} €
          </span>
        </b-col>
        <b-col
          cols="auto"
          class="text-right"
        >
          <span class="text-dark-gray font-very-small font-weight-bold">
            TIPO IMPUESTO:
            <TaxesSelect
              :value="mainCertificationInvoiceTax"
              :type="mainCertificationTaxType"
              show-type-selector
              @option-selected="updateTax"
            />
          </span>
          <span class="text-gray-50 justify-content-end d-inline-flex total-value-label">
            {{ (mainCertification ? mainCertification.total_tax : 0) | numberToLocalString }} €
          </span>
        </b-col>
        <b-col
          cols="auto"
          class="text-right"
        >
          <span class="text-dark-gray font-very-small font-weight-bold"> TOTAL A FACTURAR: </span>
          <span class="text-gray-50 justify-content-end d-inline-flex total-value-label">
            {{ (mainCertification ? mainCertification.total_invoice : 0) | numberToLocalString }} €
          </span>
        </b-col>
        <b-col
          cols="auto"
          class="text-right"
        >
          <span class="text-dark-gray font-very-small font-weight-bold">RETENCIÓN: </span>
          <span class="text-gray-50 justify-content-end d-inline-flex total-value-label">
            {{ (mainCertification ? mainCertification.total_retention : 0) | numberToLocalString }} €
          </span>
        </b-col>
        <b-col
          cols="auto"
          class="text-right"
        >
          <span class="text-dark-gray font-very-small font-weight-bold">LÍQUIDO A PERCIBIR: </span>
          <span class="text-gray-50 justify-content-end d-inline-flex total-value-label">
            {{ (mainCertification ? mainCertification.total_liquid : 0) | numberToLocalString }} €
          </span>
        </b-col>
      </b-row>
    </b-overlay>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MaintenanceCertificationsApi from "@/api/maintenance-certifications";

import ApiRestService from '@/api/base-api'
import TaxesSelect from '@/components/tax/select/TaxesSelect.vue'
import ProductSelect from '@/components/products/select/ProductSelect.vue'

export default {
  name: 'MaintenanceCertificationsLinesTable',
  components: {
    ProductSelect,
    TaxesSelect,
  },
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      columns: [
        {
          label: 'PRODUCTO',
          key: 'product',
          thStyle: { 'min-width': '150px' },
          sortable: false,
        },
        {
          label: 'DESCRIPCIÓN',
          key: 'description',
          sortable: false,
        },
        {
          label: 'IMPORTE',
          key: 'amount',
          sortable: false,
        },
        {
          label: 'UNIDADES',
          key: 'units',
          sortable: false,
          thStyle: { width: '125px' },
        },
        {
          label: 'TOTAL',
          key: 'total',
          sortable: false,
          thStyle: { 'min-width': '125px' },
          thClass: 'text-right px-2',
          tdClass: 'text-right px-2',
        },
        {
          label: '',
          key: 'actions',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '75px' },
        },
      ],
    }
  },
  computed: {
    ...mapGetters('maintenanceCertifications', {
      mainCertification: 'getMaintenanceCertification',
      mainCertificationLines: 'getMaintenanceCertificationLines'
    }),
    mainCertificationInvoiceTax() {
      return this.mainCertification?.tax
    },
    mainCertificationTaxType() {
      return this.mainCertification?.tax?.type
    },
  },
  methods: {
    ...mapActions('maintenanceCertifications', ['loadMaintenanceCertification', 'setTax']),
    async fetchData() {
      try {
        await this.loadMaintenanceCertification(this.mainCertification?.id)
      } catch (error) {
        console.error(error)
        this.$toast.error('Ocurrió un error al obtener el listado de lineas de factura. Por favor inténtelo de nuevo mas tarde.')
      }
    },
    async updateLine(line) {
      this.loading = true
      
      try {
        await ApiRestService.edit(
          line.id,
          { ...line },
          this.$data.$constants.RESOURCES.RESOURCE_MAINTENANCE_CERTIFICATIONS_LINES,
        )
        await this.fetchData()
      } finally {
        this.loading = false
      }
    },
    updateTax(tax) {
      this.setTax(tax)
      this.updateMainCertification()
    },
    async updateMainCertification() {
      this.loading = true
      try {
        await MaintenanceCertificationsApi.edit(this.mainCertification?.id, this.mainCertification)
        await this.fetchData()
      } finally {
        this.loading = false
      }
    },
    handleProductSelectChange(product, line) {
      const mainCertificationLine = { ...line }
      mainCertificationLine.product_id = product?.id || ''

      this.updateLine(mainCertificationLine)
    },
    async handleDeleteIconClick(lineId) {
      const response = await this.$modal.show({
        title: '¿Está seguro?',
        text: 'Se borrará el concepto o importe',
      })

      if (!response.isConfirmed) {
        return
      }

      this.loading = true
      try {
        await ApiRestService.delete(lineId, this.$data.$constants.RESOURCES.RESOURCE_MAINTENANCE_CERTIFICATIONS_LINES)
        await this.loadMaintenanceCertification(this.mainCertification?.id)
      } finally {
        this.loading = false
      }
    },
    async createEmptyLine() {
      this.loading = true
      try {
        await ApiRestService.create(
          { maintenance_certification_id: this.mainCertification?.id },
          this.$data.$constants.RESOURCES.RESOURCE_MAINTENANCE_CERTIFICATIONS_LINES,
        )
        await this.loadMaintenanceCertification(this.mainCertification?.id)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
.total-value-label {
  width: 150px;
}
</style>
