var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseCard',{ref:"base-card",attrs:{"loading":_vm.loading,"title-visible":false,"footer-visible":false},scopedSlots:_vm._u([{key:"title-free",fn:function(){return [_c('b-row',{staticClass:"m-2",attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"cols":"auto"}},[_c('h2',{staticClass:"font-weight-bold text-capitalize text-dark mb-0"},[_vm._v(" "+_vm._s(_vm.mainCertification ? _vm.mainCertification.code : "")+" "),(_vm.status.name === 'Facturada')?_c('StatusBadge',{staticClass:"font-small-3 ml-1",attrs:{"status":_vm.status.name,"text":_vm.status.name}}):_c('StatusSelect',{staticClass:"font-small-3 d-inline-block status-select",attrs:{"type":_vm.statusType,"clearable":false},on:{"selection-change":function($event){return _vm.handleUpdateMaintenance('status', $event)}},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('div',{staticClass:"d-flex align-items-center"},[(!_vm.editDate)?[_c('small',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.mainCertificationDateText))]),_c('b-link',[_c('span',{staticClass:"pl-1",on:{"click":function($event){_vm.editDate = true}}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Editar","icon":"Edit2Icon","size":"15"}})],1)])]:_vm._e(),(_vm.editDate)?_c('b-form-group',{attrs:{"label":"Fecha","label-for":"certification-date"}},[_c('b-input-group',[_c('b-form-datepicker',{attrs:{"id":"certification-date","value":_vm.mainCertification.maintenance_certification_date,"date-format-options":{
                  day: 'numeric',
                  month: 'numeric',
                  year: 'numeric',
                },"size":"sm","hide-header":true,"placeholder":"Fecha","start-weekday":"1"},on:{"input":function($event){return _vm.handlerUpdateDate($event)}}}),_c('b-input-group-append',[_c('b-btn',{attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){_vm.editDate = false}}},[_vm._v(" Cancelar ")])],1)],1)],1):_vm._e()],2)]),_c('b-col',{staticClass:"d-flex align-items-center",attrs:{"cols":"auto"}},[(_vm.isMaintenance)?_c('b-form-group',{attrs:{"label":"Contrato de mantenimiento","label-for":"certification-client"}},[_c('BaseSelect',{attrs:{"label":"name","clearable":false,"placeholder":'Contrato de mantenimiento',"value":_vm.mainCertification ? _vm.mainCertification.job_contract : {},"http-body":{
              job_id: _vm.mainCertification ? _vm.mainCertification.job.id : {},
            },"resource":_vm.$data.$constants.RESOURCES.RESOURCE_JOBS_CONTRACTS},on:{"input":function($event){return _vm.handleContractSelected($event)}}})],1):_vm._e()],1),_c('b-col',{attrs:{"cols":"auto"}},[(_vm.isMaintenance)?_c('b-form-group',{attrs:{"label":"Categoria de revisión","label-for":"revision-type"}},[_c('BaseSelect',{ref:"revision-type",attrs:{"httpMethod":'get',"reduce":function (item) { return item.id; },"value":_vm.mainCertification ? _vm.mainCertification.revision_type : {},"resource":'type-revision',"clearable":false},on:{"input":function($event){return _vm.handleRevisionType($event)}}})],1):_vm._e()],1),_c('b-col',{staticClass:"ml-auto",attrs:{"cols":"auto"}},[_c('div',{staticClass:"d-flex justify-content-end align-items-center"},[(_vm.status.name === 'Pendiente de facturar')?_c('b-button',{staticClass:"rounded-sm px-2 my-1",attrs:{"variant":"primary"},on:{"click":_vm.handleInvoiceButtonClick}},[_vm._v(" Facturar ")]):_vm._e(),_c('b-link',[_c('span',{staticClass:"d-inline-block px-50 text-danger",on:{"click":function($event){return _vm.handleDeleteButtonClick()}}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Eliminar","icon":"TrashIcon","size":"18"}})],1)])],1)]),(_vm.status.name === 'Facturada')?_c('b-col',{attrs:{"cols":"12"}},[_c('b-link',{staticClass:"text-decoration-none text-indigo",attrs:{"size":"sm","to":{
            name: 'viewSaleInvoice',
            params: { id: _vm.mainCertification.sale_invoice.id },
          }}},[_c('u',[_vm._v(" Factura: "+_vm._s(_vm.mainCertification ? _vm.mainCertification.sale_invoice.code : "")+" ")])])],1):_vm._e()],1)]},proxy:true}])},[_c('hr',{staticClass:"m-0"}),_c('ProfilePanel',{attrs:{"profile":_vm.mainCertificationProfile,"autoCols":true},scopedSlots:_vm._u([{key:"Observaciones",fn:function(){return [_c('b-form-group',{attrs:{"label":"Observaciones","label-for":"observations"}},[_c('b-form-textarea',{attrs:{"id":"observations"},on:{"change":function($event){return _vm.handleUpdateMaintenance('observations', _vm.observations)}},model:{value:(_vm.observationsHandler),callback:function ($$v) {_vm.observationsHandler=$$v},expression:"observationsHandler"}})],1)]},proxy:true},{key:"documentation",fn:function(){return [_c('BaseFileInput',{attrs:{"multiple":false},on:{"change":_vm.handleFileInputChange}},[_c('b-button',{staticClass:"text-nowrap rounded-sm px-1",attrs:{"variant":"primary"}},[_vm._v(" + Adjuntar archivo ")])],1)]},proxy:true},{key:"Documentacion",fn:function(){return [_c('div',{staticClass:"font-weight-bold"},[_vm._v("Documentacion:")]),_vm._l((_vm.mainCertification.documentation),function(document){return (
          _vm.mainCertification.documentation &&
          _vm.mainCertification.documentation.length > 0
        )?_c('div',{staticClass:"d-flex justify-content-end align-items-center pt-50"},[_c('b-link',{staticClass:"text-indigo pr-1",on:{"click":function($event){return _vm.handleDownloadDocument(document)}}},[_vm._v(" "+_vm._s(document.name)+" ")]),_c('b-link',{on:{"click":function($event){return _vm.handleDeleteDocument(document.id)}}},[_c('span',{staticClass:"text-danger"},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Eliminar","icon":"TrashIcon","size":"18"}})],1)])],1):_vm._e()})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }