<template>
  <div>
    <MaintenanceCertificationProfileCard />
    <MaintenanceCertificationsLinesTable />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MaintenanceCertificationProfileCard from '@/components/maintenance-certifications/card/MaintenanceCertificationProfileCard.vue'
import MaintenanceCertificationsLinesTable from '@/components/maintenance-certifications/table/MaintenanceCertificationsLinesTable.vue'

export default {
  components: {
    MaintenanceCertificationProfileCard,
    MaintenanceCertificationsLinesTable
  },
  computed: {
    ...mapGetters('maintenanceCertifications', {
      maintenanceCertification: 'getMaintenanceCertification'
    })
  },
  methods: {
    ...mapActions('app', ['setLoading']),
    ...mapActions('maintenanceCertifications', ['loadMaintenanceCertification']),
    async loadCertification () {
      try {
        this.setLoading(true)
        await this.loadMaintenanceCertification(this.$route.params.id)
      } finally {
        this.setLoading(false)
      }
    }
  },
  mounted() {
    this.loadCertification()
  },
}
</script>